import { Injectable, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  PrismaContactFunction as ContactFunction,
  CreateNewDisciplineDto,
  PrismaDisciplineGroup as DisciplineGroup,
  DisciplineGroups,
  EImportSource,
  EPerfType,
  ERegistrationItemStatus,
  EditNewDisciplineDto,
  PrismaGender as Gender,
  ICompetition,
  ICompetitionMissingData,
  IDiscipline,
  IEventRegistration,
  INewTrials,
  IRegistrationDataAthlete,
  IRegistrationDataDiscipline,
  IRegistrationDataOrder,
  IRegistrationDetail,
  IRegistrationNewPricing,
  IRegistrationPricing,
  IRegistrationRuleLock,
  IRegistrationRulePriority,
  IRegistrationRuleQuota,
  IResult,
  IResultGridData,
  IRoundWithHeatsAndResults,
  IRuleDto,
  PrismaPaymentOption as PaymentOption,
  PrismaDisciplineOrigin,
  PrismaRegistrationRuleType as RegistrationRuleType,
  Roles,
  ShopDiscipline,
  PrismaValidation as Validation,
  PrismaVenueType as VenueType,
} from '@beathletics/api-interfaces';
import { perfFormatterFront } from '@beathletics/beathletics-ui';
import {
  getAthleticSeasonFromDate2,
  getDisciplineGroupFromEventType,
  getPerfTypeFromEventType,
  getVenueTypeFromDate,
} from '@beathletics/utils-shared';
import { TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngxs/store';
import type { CompetitionValidation, EventType, LocalTeams, NewCategory, NewCompetition } from '@prisma/client';
import { combineLatest, filter, first, map, switchMap } from 'rxjs';
import { NewCompetitionApiService } from './new-competition-api.service';
import {
  AddAthleteOrClubAsVip,
  AddCategoriesToDiscipline,
  AddDiscipline,
  AddOfficialForCompetition,
  CreateNewCompetition,
  CreateResult,
  DeleteContactOfCompetition,
  DeleteDiscipline,
  DeleteDisciplinesAndResults,
  DeleteEventResult,
  DeleteFullCompetition,
  DeleteRegistrationPricing,
  DeleteRegistrationRule,
  EditDiscipline,
  EditDisciplineEventType,
  EditResultData,
  GenerateSyncCode,
  ProcessResultFileUpload,
  ReSyncShop,
  ReloadCompetitionLogs,
  RemoveCategoryFromDiscipline,
  RemoveVip,
  ResetShop,
  SaveCompetitionData,
  SaveCustomRegistrationRule,
  SaveLocalTeamsList,
  SavePaymentOption,
  SavePricing,
  SaveRegistrationDetails,
  SelectOrLoadCompetition,
  UpdateCompetitionData,
  UpdateCompetitionValidation,
  UpdateResultsStatus,
} from './new-competition.action';
import { NewCompetitionState } from './new-competition.state';

@Injectable({
  providedIn: 'root',
})
export class NewCompetitionService {
  #store = inject(Store);
  #apiService = inject(NewCompetitionApiService);
  #transloco = inject(TranslocoService);
  selectedEventResultsFunc = () => {
    return this.#store.selectSnapshot(NewCompetitionState.resultsOfSelectedCompetitionByIds);
  };

  loadedCompetitions$ = this.#store.select(NewCompetitionState.loadedCompetitions);
  loadedCompetitions = toSignal(this.loadedCompetitions$);

  selectedEventResultsSignal = this.#store.selectSignal(NewCompetitionState.resultsOfSelectedCompetitionByIds);

  registrationEmail = this.#store.selectSnapshot(NewCompetitionState.selectedCompetitionRegistrationEmail);

  selectedCompetitionData$ = this.#store.select(NewCompetitionState.selectedCompetitionData);
  selectedCompetitionData = toSignal(this.#store.select(NewCompetitionState.selectedCompetitionData));

  selectedCompetition$ = this.#store.select(NewCompetitionState.selectedCompetition);
  selectedCompetition = toSignal(this.#store.select(NewCompetitionState.selectedCompetition), { requireSync: true });

  loadedResults$ = this.#store.select(NewCompetitionState.loadedResults);

  selectedCompetitionLogs$ = this.#store.select(NewCompetitionState.selectedCompetitionLogs);

  selectedCompetitionContacts$ = this.#store.select(NewCompetitionState.selectedCompetitionContacts);

  selectedCompetitionDisciplinesAndResults$ = this.#store.select(
    NewCompetitionState.selectedCompetitionDataAndResultsOfSelectedCompetition,
  );
  selectedCompetitionDisciplinesAndResults = toSignal(this.selectedCompetitionDisciplinesAndResults$);

  competitionResults$ = this.#store.select(NewCompetitionState.resultsOfSelectedCompetition);
  competitionResults = toSignal(this.competitionResults$);

  lastSeasonBests$ = this.#store.select(NewCompetitionState.lastAthleteSeasonBestsOfSelectedCompetition);

  registrationVips$ = this.#store.select(NewCompetitionState.selectedCompetitionVips);

  registrationLocalTeams$ = this.#store.select(NewCompetitionState.selectedCompetitionRegistrationLocalTeams);

  registrationDetails$ = this.#store.select(NewCompetitionState.selectedCompetitionRegistrationDetails);
  registrationDetails = toSignal(this.registrationDetails$);

  registrationRules$ = this.#store.select(NewCompetitionState.selectedCompetitionRegistrationRules);
  registrationRules = toSignal(this.getRegistrationRulesByType());

  registrationPrices$ = this.#store.select(NewCompetitionState.selectedCompetitionRegistrationPrices);
  registrationPrices = toSignal(this.registrationPrices$);

  getSelectedCompetition = () => this.#store.selectSignal(NewCompetitionState.selectedCompetition);

  competitionVenue = computed(() => {
    const competition = this.selectedCompetitionData();
    return getVenueTypeFromDate(new Date(competition?.startDate || 0));
  });

  selectedRegistrationDisciplinesByRef = computed(() => {
    const competition = this.selectedCompetitionData();

    return (competition?.disciplines || [])
      .filter((d) => d.origin === PrismaDisciplineOrigin.REGISTRATION)
      .reduce(
        (acc, d) => {
          acc[d.shopReference || competition?.eventNumber + '/' + d.name] = d;
          return acc;
        },
        {} as Record<string, IDiscipline>,
      );
  });

  selectOrLoadCompetition(eventNumber: string) {
    return this.#store.dispatch(new SelectOrLoadCompetition(eventNumber));
  }

  getCompetitionWithDisciplinesFromOrigin(origin: PrismaDisciplineOrigin) {
    return computed(() => {
      const competition = this.selectedCompetitionData();
      if (competition?.disciplines && competition.disciplines.length > 0) {
        return {
          ...competition,
          disciplines: competition.disciplines.filter((d) => d.origin === origin),
        };
      }
      return competition;
    });
  }

  allDisciplinesOrigins = computed(() => {
    const competition = this.selectedCompetitionData();
    const originsOrder = {
      [PrismaDisciplineOrigin.CALENDAR]: 1,
      [PrismaDisciplineOrigin.REGISTRATION]: 2,
      [PrismaDisciplineOrigin.LIVE_RESULTS]: 3,
      [PrismaDisciplineOrigin.RESULT_FILE]: 4,
    };
    const availableOrigins: PrismaDisciplineOrigin[] = [
      ...new Set((competition?.disciplines || []).map((d) => d.origin)),
    ].sort((a, b) => originsOrder[b] - originsOrder[a]);
    return availableOrigins;
  });

  getRegistrationRulesByType() {
    return this.registrationRules$.pipe(
      map((allRules) => {
        const now = Date.now();
        if (allRules) {
          const sortedRules: {
            priority?: IRegistrationRulePriority[];
            quota?: IRegistrationRuleQuota[];
            lock?: IRegistrationRuleLock[];
          } = {
            priority: [],
            quota: [],
            lock: [],
          };
          for (const r of allRules) {
            if (r.type === RegistrationRuleType.PRIORITY) {
              const priorityRule = r.rule as unknown as IRegistrationRulePriority;
              const start = new Date(priorityRule.startDate).getTime();
              const end = new Date(priorityRule.endDate).getTime();
              if (start <= now && end >= now) {
                sortedRules.priority?.push(priorityRule);
              }
            }
            if (r.type === RegistrationRuleType.QUOTA) {
              sortedRules.quota?.push(r.rule as unknown as IRegistrationRuleQuota);
            }
            if (r.type === RegistrationRuleType.LOCK) {
              sortedRules.lock?.push(r.rule as unknown as IRegistrationRuleLock);
            }
          }
          if (sortedRules.priority?.length === 0) {
            delete sortedRules.priority;
          }
          if (sortedRules.quota?.length === 0) {
            delete sortedRules.quota;
          }
          return sortedRules;
        }
        return allRules;
      }),
    );
  }

  createNewCompetition = (
    competition: Partial<Omit<NewCompetition, 'startDate' | 'endDate'>> & {
      startDate: string;
      endDate: string;
    },
    organizer: { abbr: string; type: 'club' | 'federation' },
    venueId?: string,
  ) => this.#store.dispatch(new CreateNewCompetition(competition, organizer, venueId));

  updateCompetitionData = (
    id: string,
    data: {
      competition: Partial<Omit<NewCompetition, 'startDate' | 'endDate'>> & {
        startDate: string;
        endDate: string;
      };
      organizer?: { abbr: string; type: 'club' | 'federation' };
      venueId?: string;
    },
  ) => this.#store.dispatch(new UpdateCompetitionData(id, data));

  addDiscipline = (discipline: CreateNewDisciplineDto) => this.#store.dispatch(new AddDiscipline(discipline));

  editDiscipline = (discipline: EditNewDisciplineDto) => this.#store.dispatch(new EditDiscipline(discipline));

  deleteDiscipline = (disciplineId: string, eventNumber: string) =>
    this.#store.dispatch(new DeleteDiscipline(disciplineId, eventNumber));

  // TODO : sort groups ? how to, which order ?
  getValidDisciplinesByGroupForCat = (categoryAbbr: string, priorityDisciplines?: string[]) =>
    this.selectedCompetitionData$.pipe(
      map((competition) => {
        const disciplinesByGroup = new Map<string, IDiscipline[]>();
        for (const discipline of competition.disciplines) {
          if (!priorityDisciplines?.includes(discipline.id)) {
            const group = this.#transloco.translate(
              'EVENT_TYPE_GROUP_' + (discipline?.eventType?.discipline_group || 'UNKNOWN'),
            );
            if (group) {
              const disciplines = disciplinesByGroup.get(group) || [];
              disciplines.push(discipline);
              if (!disciplinesByGroup.has(group)) {
                disciplinesByGroup.set(group, disciplines);
              }
            }
          }
        }
        const sortedByGroup = [...disciplinesByGroup.entries()].map(([name, disciplines]) => ({
          name,
          disciplines: disciplines
            .filter((d) => {
              let toKeep = false;
              if (d?.categories && d?.categories?.length > 0) {
                for (const cat of d.categories) {
                  if (
                    cat.abbr === categoryAbbr ||
                    (categoryAbbr.slice(0, 1) === 'M' && (cat.abbr === 'M35+' || cat.abbr === 'SEN M')) ||
                    (categoryAbbr.slice(0, 1) === 'W' && (cat.abbr === 'W35+' || cat.abbr === 'SEN F'))
                  ) {
                    toKeep = true;
                    break;
                  }
                  if (cat.index === 'TC') {
                    const gender = categoryAbbr.split(' ')[1] === 'F' ? Gender.Female : Gender.Male;
                    const catIndex = categoryAbbr.substring(0, 2);
                    if (
                      cat.requiredGender === gender &&
                      catIndex !== 'KA' &&
                      catIndex !== 'BE' &&
                      catIndex !== 'PU' &&
                      catIndex !== 'MI'
                    ) {
                      toKeep = true;
                      break;
                    }
                  }
                }
              }
              return toKeep;
            })
            .sort((a, b) => {
              if (a?.eventType && b?.eventType) {
                if (a.eventType?.distance !== 0) {
                  return a.eventType.distance - b.eventType.distance;
                } else {
                  return (a.eventType?.name_fr || 'z').localeCompare(b.eventType?.name_fr || 'z');
                }
              }
              return a.name.localeCompare(b.name);
            }),
        }));
        return sortedByGroup.filter((g) => g.disciplines.length > 0);
      }),
    );

  hasValidationStarted = () =>
    this.competitionResults$.pipe(
      map((results) => {
        for (const res of results) {
          if (res.validationStatus !== Validation.pending) {
            return true;
          }
        }
        return false;
      }),
    );

  isReadyToSendMailToRole = (role: Roles.Ja | Roles.Homologation) =>
    this.competitionResults$.pipe(
      map((results) => {
        if (results.length === 0) {
          return false;
        }
        let isOk = true;
        for (const res of results) {
          if (role === Roles.Ja) {
            if (
              res.validationStatus === Validation.h_certified ||
              res.validationStatus === Validation.h_not_certified
            ) {
              return false;
            }
          } else {
            if (res.validationStatus === Validation.pending) {
              isOk = false;
            }
          }
        }
        return isOk;
      }),
    );

  getValidationStatusByDiscipline = () =>
    combineLatest([this.selectedCompetitionData$, this.loadedResults$]).pipe(
      filter(([c, r]) => !!c && !!r),
      map(([competition, results]) => {
        const validationByDiscipline: { [id: string]: Validation } = {};
        for (const discipline of competition.disciplines) {
          validationByDiscipline[discipline.id] = this.setDisciplineValidationStatus(
            discipline,
            results[competition.eventNumber],
          );
          if (discipline?.children && discipline.children.length > 0) {
            for (const child of discipline.children) {
              validationByDiscipline[child.id] = this.setDisciplineValidationStatus(
                child,
                results[competition.eventNumber],
              );
            }
          }
        }
        return validationByDiscipline;
      }),
    );

  setDisciplineValidationStatus(
    discipline: IDiscipline,
    results: {
      [id: string]: IResult;
    },
  ) {
    let validation: Validation = Validation.pending;
    let toIgnore = 0;
    const status: { [key in Validation]: number } = {
      [Validation.pending]: 0,
      [Validation.ja_approved]: 0,
      [Validation.ja_not_approved]: 0,
      [Validation.h_certified]: 0,
      [Validation.h_not_certified]: 0,
    };
    if (discipline?.results && discipline.results.length > 0) {
      for (const { id } of discipline.results) {
        const result = results?.[id];
        if (result) {
          status[result?.validationStatus]++;
        } else {
          toIgnore++;
        }
      }
      if (status.pending > 0) {
        validation = Validation.pending;
      } else if (status.ja_not_approved > 0) {
        validation = Validation.ja_not_approved;
      } else if (status.ja_approved > 0) {
        validation = Validation.ja_approved;
      } else if (status.h_certified + status.h_not_certified === discipline.results.length - toIgnore) {
        validation = Validation.h_certified;
      }
    }
    return validation;
  }

  getLoadedDisciplineById(disciplineId: string | null | undefined) {
    const disciplines = this.getSelectedCompetitionSnapshot().disciplines;
    const discipline = disciplines.find((d) => d.id === disciplineId);
    if (!discipline) {
      for (const d of disciplines) {
        if (d.children && d.children.length > 0) {
          const child = d.children.find((c) => c.id === disciplineId);
          if (child) {
            return child;
          }
        }
      }
    }
    return discipline;
  }

  getLoadedDisciplineByRef(reference: string, origin: PrismaDisciplineOrigin): ShopDiscipline | undefined {
    const disciplines = this.getSelectedCompetitionSnapshot().disciplines as ShopDiscipline[];
    const discipline = disciplines.find((d) => d.shopReference === reference && d.origin === origin);
    if (!discipline) {
      for (const d of disciplines) {
        if (d.children && d.children.length > 0) {
          const child = d.children.find((c) => c.shopReference === reference && d.origin === origin) as
            | ShopDiscipline
            | undefined;
          if (child) {
            return child;
          }
        }
      }
    }
    return discipline;
  }

  getLoadedResultById(resultId: string) {
    return this.#store.selectSnapshot(NewCompetitionState.getResultById)(resultId);
  }

  saveCompetitionData = (eventNumber: string, data: Partial<ICompetition>) =>
    this.#store.dispatch(new SaveCompetitionData(eventNumber, data));

  updateValidationsStatus = (status: Validation, resultsIds: string[]) =>
    this.#store.dispatch(new UpdateResultsStatus(status, resultsIds));

  updateCompetitionsValidation = (status: CompetitionValidation, eventNumbers: string[]) =>
    this.#store.dispatch(new UpdateCompetitionValidation(status, eventNumbers));

  reloadCompetitionLogs = (eventNumber: string) => this.#store.dispatch(new ReloadCompetitionLogs(eventNumber));

  removeCatFromDiscipline = (eventNumber: string, disciplineId: string, catId: string) =>
    this.#store.dispatch(new RemoveCategoryFromDiscipline(eventNumber, disciplineId, catId));

  addCatToDiscipline = (eventNumber: string, disciplineId: string, catsId: string[]) =>
    this.#store.dispatch(new AddCategoriesToDiscipline(eventNumber, disciplineId, catsId));

  connectEventTypeWithDiscipline = (disciplineId: string, eventTypeId: string, parentId?: string) =>
    this.#store.dispatch(new EditDisciplineEventType(disciplineId, eventTypeId, parentId));

  saveCompetitionRegistrationDetails = (details: IRegistrationDetail & { toEdit?: boolean; toSave?: boolean }) =>
    this.#store.dispatch(new SaveRegistrationDetails(details));

  saveRegistrationPaymentOption = (competitionId: string, option: PaymentOption) =>
    this.#store.dispatch(new SavePaymentOption(competitionId, option));

  saveRegistrationPricing = (pricing: IRegistrationNewPricing | IRegistrationPricing) =>
    this.#store.dispatch(new SavePricing(pricing));

  deleteRegistrationPricing = (pricingId: string) => this.#store.dispatch(new DeleteRegistrationPricing(pricingId));

  addRegistrationVip(data: { competitionId: string; athleteId?: string; clubId?: string }) {
    return this.#store.dispatch(new AddAthleteOrClubAsVip(data));
  }

  findVipIdByTypeAndId(type: 'athlete' | 'club', id: string) {
    const competition = this.#store.selectSnapshot(NewCompetitionState.selectedCompetitionData);
    const allVips = competition?.registrationDetail?.registrationsVip || [];
    const vip = allVips.find((v) => (type === 'athlete' ? v.athleteId === id : v.clubId === id));
    return vip?.id;
  }

  removeVipFromRegistration(vipId: string) {
    return this.#store.dispatch(new RemoveVip(vipId));
  }

  getRegistrationVipSnapshot() {
    const competition = this.#store.selectSnapshot(NewCompetitionState.selectedCompetitionData);
    return competition?.registrationDetail?.registrationsVip || [];
  }

  saveRegistrationCustomRule(data: IRuleDto) {
    return this.#store.dispatch(new SaveCustomRegistrationRule(data));
  }

  removeRegistrationCustomRule(ruleId: string) {
    return this.#store.dispatch(new DeleteRegistrationRule(ruleId));
  }

  saveRegistrationAllowedTeams(eventNumber: string, teams: LocalTeams[]) {
    return this.#store.dispatch(new SaveLocalTeamsList(eventNumber, teams));
  }

  getCompetitionOfficialsByRole(role: Roles) {
    return toSignal(
      this.selectedCompetitionData$.pipe(
        map((competition) => {
          const contactFunction =
            role === Roles.Ja
              ? ContactFunction.Ja
              : role === Roles.Homologation
                ? ContactFunction.Homologation
                : ContactFunction.Secretary;
          return competition?.contacts?.filter((c) => c.function === contactFunction);
        }),
      ),
    );
  }

  linkOfficialWithCompetition(official: {
    email: string;
    firstName: string;
    lastName: string;
    role: Roles.Secretary | Roles.Ja | Roles.Homologation;
  }) {
    const eventNumber = this.#store.selectSnapshot(NewCompetitionState.selectedCompetition);
    return this.#store.dispatch(new AddOfficialForCompetition(eventNumber, official));
  }

  deleteContactOfCompetition(contactId: string) {
    const eventNumber = this.#store.selectSnapshot(NewCompetitionState.selectedCompetition);
    return this.#store.dispatch(new DeleteContactOfCompetition(eventNumber, contactId));
  }

  getSelectedCompetitionSnapshot = () => this.#store.selectSnapshot(NewCompetitionState.selectedCompetitionData);

  processResultFileUpload(eventNumber: string, file: File, source: EImportSource, registrationSchedule = false) {
    return this.#store.dispatch(new ProcessResultFileUpload(eventNumber, file, source, registrationSchedule));
  }

  editResultData(eventNumber: string, result: IResult, recalculateRanks: boolean, disciplineParentId?: string | null) {
    return this.#store.dispatch(new EditResultData(eventNumber, result, recalculateRanks, disciplineParentId));
  }

  createNewResult(
    eventNumber: string,
    result: Omit<IResult, 'id'>,
    athletes: {
      id: string;
      order: number | null;
      bib: number;
      clubId?: string;
      category: string;
    }[],
    recalculateRanks: boolean,
    children?: Omit<IResult, 'id'>[],
  ) {
    return this.#store.dispatch(new CreateResult(eventNumber, result, athletes, recalculateRanks, children));
  }

  deleteEventResult(eventNumber: string, resultId: string, recalculateRanks: boolean) {
    return this.#store.dispatch(new DeleteEventResult(eventNumber, resultId, recalculateRanks));
  }

  deleteAllDisciplinesAndResults(
    eventNumber: string,
    autoClearExisting = false,
    deleteFromError = false,
    disciplineOrigin?: PrismaDisciplineOrigin,
  ) {
    return this.#store.dispatch(
      new DeleteDisciplinesAndResults(eventNumber, autoClearExisting, deleteFromError, disciplineOrigin),
    );
  }

  deleteFullCompetition(eventNumber: string) {
    return this.#store.dispatch(new DeleteFullCompetition(eventNumber));
  }

  getIfEventNumberIsAlreadyUsed(eventNumber: string) {
    return this.#apiService.getIfEventNumberExists(eventNumber);
  }

  getLastSeasonBestIfBeaten(resultValue: number | undefined, athleteId: string, eventType: EventType) {
    return this.lastSeasonBests$.pipe(
      map((lastSeasonBests) => {
        const lastSeasonBest = lastSeasonBests.find(
          (lsb) => lsb.athleteId === athleteId && lsb.eventTypeId === eventType.id,
        );
        if (lastSeasonBest && resultValue) {
          if (
            (eventType.low2high === 0 && +lastSeasonBest.rankingPerf < resultValue) ||
            (eventType.low2high === 1 && +lastSeasonBest.rankingPerf > resultValue)
          ) {
            return perfFormatterFront(
              +lastSeasonBest.rankingPerf,
              eventType?.result_type?.toLowerCase(),
              eventType?.discipline_group as DisciplineGroups,
            );
          }
        }
        return undefined;
      }),
    );
  }

  getSelectedCompetitionDisciplinesByGroup(
    disciplineOrigin?: PrismaDisciplineOrigin,
    dissociateChildren = false,
    groupByEventType = false,
  ) {
    return this.selectedCompetitionDisciplinesAndResults$.pipe(
      map(({ competition, results }) =>
        this.mapDisciplinesByGroup(competition, results, disciplineOrigin, dissociateChildren, groupByEventType),
      ),
    );
  }

  getSelectedCompetitionDisciplinesByGroupSignal(
    disciplineOrigin?: PrismaDisciplineOrigin,
    dissociateChildren = false,
    groupByEventType = false,
  ) {
    const data = this.selectedCompetitionDisciplinesAndResults();
    if (data) {
      return this.mapDisciplinesByGroup(
        data.competition,
        data.results,
        disciplineOrigin,
        dissociateChildren,
        groupByEventType,
      );
    }
    return {};
  }

  mapDisciplinesByGroup(
    competition: ICompetition,
    results: IResult[],
    disciplineOrigin?: PrismaDisciplineOrigin,
    dissociateChildren = false,
    groupByEventType = false,
  ) {
    const allDisciplines = disciplineOrigin
      ? competition.disciplines.filter((d) => d.origin === disciplineOrigin)
      : competition.disciplines;

    if (allDisciplines?.length > 0) {
      const disciplinesMap: Map<string, (IDiscipline & { parentName?: string })[]> = new Map();

      for (const discipline of allDisciplines) {
        this.setDisciplineMap(disciplinesMap, discipline, groupByEventType);

        if (dissociateChildren && discipline?.children && discipline.children.length > 0) {
          for (const child of discipline.children) {
            this.setDisciplineMap(disciplinesMap, child, groupByEventType, discipline.name);
          }
        }
      }
      const disciplines = [...disciplinesMap.entries()].map(([group, disciplines]) => ({
        name: group,
        disciplines: disciplines
          .map((d) => {
            if (d?.children && d.children.length > 0) {
              const children = [...d.children];
              return {
                ...d,
                categoriesString: d.categories?.map((c) => c.abbr).join(', '),
                children: children.sort((a, b) => {
                  // * combinedOrder on discipline is not saved, the children order info is 'roundNumber' on results
                  if (a?.combinedOrder || b?.combinedOrder) {
                    return (a.combinedOrder || 0) - (b.combinedOrder || 999);
                  } else {
                    if (a?.results?.[0]?.id && b?.results?.[0]?.id) {
                      const orderA = results.find((res) => res.id === a?.results?.[0].id);
                      const orderB = results.find((res) => res.id === b?.results?.[0].id);
                      return +(orderA?.roundNumber || 0) - +(orderB?.roundNumber || 999);
                    }
                  }
                  return 0;
                }),
              };
            }
            return { ...d, categoriesString: d?.categories?.map((c) => c.abbr).join(', ') };
          })
          .sort((a, b) => {
            const catA = a?.categories?.[0]?.requiredGender || 'Z';
            const catB = b?.categories?.[0]?.requiredGender || 'Z';
            return catA > catB ? 1 : -1;
          })
          .sort((a, b) => {
            const ageA = a?.categories?.[0]?.requiredMinAge || 1;
            const ageB = b?.categories?.[0]?.requiredMinAge || 1;
            return ageA - ageB;
          })
          .sort((a, b) => (a.eventType?.sort_order || 0) - (b.eventType?.sort_order || 0)),
      }));
      return disciplines.reduce(
        (acc, curr) => {
          acc[curr.name] = curr;
          return acc;
        },
        {} as {
          [key: string]: { name: string; disciplines: (IDiscipline & { categoriesString?: string })[] };
        },
      );
    }
    return {};
  }

  setDisciplineMap(
    dMap: Map<string, (IDiscipline & { parentName?: string })[]>,
    discipline: IDiscipline,
    groupByEventType: boolean,
    parentName?: string,
  ) {
    const key = groupByEventType
      ? discipline?.eventType?.discipline_group || 'Groupe introuvable'
      : discipline?.disciplineGroup && discipline.disciplineGroup !== DisciplineGroup.Unknown
        ? discipline.disciplineGroup
        : getDisciplineGroupFromEventType(discipline?.eventType);
    const disciplines = dMap.get(key) || [];
    if (parentName) {
      disciplines.push({ ...discipline, parentName });
    } else {
      disciplines.push(discipline);
    }
    if (!dMap.has(key)) {
      dMap.set(key, disciplines);
    }
  }

  getAllResultsForGridWithDiscipline(disciplineOrigin: PrismaDisciplineOrigin) {
    return this.selectedCompetitionDisciplinesAndResults$.pipe(
      map((data) => {
        const toReturn: {
          result: IResultGridData;
          discipline: IDiscipline;
          parent?: IDiscipline;
        }[] = [];
        const formattedResults = this.flattenResultsForGrid(data.results);
        for (const result of formattedResults) {
          const discipline = this.getLoadedDisciplineById(result.fullResult.disciplineId);
          if (discipline && discipline.origin === disciplineOrigin) {
            if (discipline.parentId) {
              const parent = this.getLoadedDisciplineById(discipline.parentId);
              toReturn.push({
                result,
                discipline,
                parent,
              });
            } else {
              toReturn.push({ result, discipline });
            }
          }
        }
        return toReturn.sort((a, b) => a.discipline.name.localeCompare(b.discipline.name));
      }),
    );
  }

  groupAndMapResultsForValidationGrid(eventNumber: string, disciplineId: string, parentId: string | null) {
    return combineLatest([this.selectedCompetitionData$, this.loadedResults$]).pipe(
      map(([competition, allResults]) => {
        let discipline = competition?.disciplines.find((d) => d.id === (parentId || disciplineId));
        if (parentId) {
          discipline = discipline?.children?.find((d) => d.id === disciplineId);
        }
        const selectedResults = [];
        for (const { id } of discipline?.results || []) {
          const result = allResults[eventNumber][id];
          if (result) {
            selectedResults.push(result);
          }
        }
        return this.groupResultsByRoundAndHeat(selectedResults, true, true);
      }),
    );
  }

  groupResultsByRoundAndHeat(
    results: IResult[],
    flattenResults = false,
    forAdminGrid = false,
  ): IRoundWithHeatsAndResults[] {
    const resultsByRound: Map<
      string,
      {
        name: string;
        order: number;
        official: Date | null;
        scheduled: Date | null;
        results: IResult[];
      }
    > = new Map();
    let showBib = false;
    const rounds = [] as string[];
    for (const result of results) {
      if (result?.athletes?.[0]?.bibIdentifier) {
        showBib = true;
      }
      const roundId = (result?.roundName || 'round') + (result?.roundNumber || 1);
      if (!rounds.includes(roundId)) {
        rounds.push(roundId);
      }
      const roundData = {
        name: result?.roundName || 'round',
        order: result?.roundNumber || rounds.indexOf(roundId) + 1,
        official: result?.roundTimeOfficial,
        scheduled: result?.roundTimeScheduled,
      };
      const round = resultsByRound.get(roundId) || {
        ...roundData,
        results: [],
      };
      round.results.push(result);
      if (!resultsByRound.has(roundId)) {
        resultsByRound.set(roundId, round);
      }
    }
    return [...resultsByRound.entries()]
      .map(([id, data]) => ({
        id,
        name: data.name,
        order: data.order,
        official: data.official,
        scheduled: data.scheduled,
        showBib,
        heats: this.groupResultsByHeat(data.results, flattenResults, forAdminGrid),
      }))
      .sort((a, b) => a.order - b.order);
  }

  groupResultsByHeat(results: IResult[], flattenResults = false, forAdminGrid = false) {
    const resultsByHeat: Map<
      number | string,
      {
        official: Date | null;
        scheduled: Date | null;
        results: IResult[];
      }
    > = new Map();
    const discipline = this.getLoadedDisciplineById(results?.[0]?.disciplineId);
    const showWind = discipline?.eventType?.wind_mode === 'H';
    let heatUnknown = false;
    for (const result of results) {
      const isCombinedTotal = result?.newTrials?.[0]?.perftype === EPerfType.POINTS;
      const heatNumber = isCombinedTotal
        ? 1
        : (result?.heatNumber ??
          (result?.category?.requiredGender === Gender.Female ||
          result?.athletes?.[0]?.athlete?.person?.gender === Gender.Female
            ? 'Femmes'
            : 'Hommes'));
      if (typeof heatNumber !== 'number' && !heatUnknown) {
        heatUnknown = true;
      }
      const heatData = {
        official: result?.heatTimeOfficial,
        scheduled: result?.heatTimeScheduled,
      };
      const heat = resultsByHeat.get(heatNumber) || {
        ...heatData,
        results: [],
      };
      heat.results.push(result);
      if (!resultsByHeat.has(heatNumber)) {
        resultsByHeat.set(heatNumber, heat);
      }
    }
    return [...resultsByHeat.entries()]
      .map(([order, data]) => ({
        order,
        official: data.official,
        scheduled: data.scheduled,
        wind: showWind ? data.results[0]?.newTrials?.[0]?.windSpeed : undefined,
        results: flattenResults ? this.flattenResultsForGrid(data.results, forAdminGrid, heatUnknown) : results,
      }))
      .sort((a, b) => {
        if (typeof a.order === 'number' && typeof b.order === 'number') {
          return a.order - b.order;
        } else {
          return 0;
        }
      });
  }

  flattenResultsForGrid(results: IResult[], forAdminGrid = false, sortByPerf = false): IResultGridData[] {
    return results
      .map((result) => {
        // ! careful about relays !
        // ? how to display them in the grid ? only teamName ?
        // TODO : generate grid data & columns from eventType
        // * wind ? points ? only points ? trials ? vertical jumps ?
        const athlete = result?.teamName
          ? result.teamName
          : result?.athletes?.[0]?.athlete?.person?.lastName && result?.athletes?.[0]?.athlete?.person?.firstName
            ? result.athletes[0].athlete.person.lastName + ', ' + result.athletes[0].athlete.person.firstName
            : (
                result?.athletes?.[0]?.athlete?.person?.metadata as {
                  xlsData?: {
                    fullName?: string;
                  };
                }
              )?.xlsData?.fullName || 'NO NAME FOUND';

        const bib = result?.athletes?.[0]?.bibIdentifier || '-';
        let club =
          result?.club?.abbr ||
          result?.athletes?.[0]?.club?.abbr ||
          result?.federation?.abbr ||
          result?.athletes?.[0]?.federation?.abbr ||
          'NA';

        const calculatedCat = result.athletes?.[0]?.calculatedCategory;
        const cat = result.athletes?.[0]?.category?.abbr || result?.category?.abbr;
        const category = calculatedCat && calculatedCat !== cat ? calculatedCat : cat || 'N.C.';

        if ((result?.athletes || [])?.length > 1) {
          for (const a of result.athletes || []) {
            const c = a?.club?.abbr || a?.federation?.abbr || 'NA';
            if (c !== club) {
              club = 'NA';
              break;
            }
          }
        }

        const liveId =
          result?.athletes && result.athletes.length > 1
            ? undefined
            : result?.athletes?.[0]?.athlete?.liveId || undefined;

        const bestPerf = result?.competitionFeature
          ? {
              perf: result.competitionFeature,
              wind: undefined,
              perfType: (result?.newTrials?.[0]?.perftype as EPerfType) || EPerfType.UNKNOWN,
            }
          : result
            ? (this.getBestPerfFromResultTrials(result.newTrials, result.disciplineId) as {
                perf: string;
                wind: number | undefined;
                perfType: EPerfType;
              })
            : undefined;

        const certifiablePerf = result?.competitionFeature
          ? {
              perf: result.competitionFeature,
              wind: undefined,
              perfType: result?.newTrials?.[0]?.perftype || EPerfType.UNKNOWN,
            }
          : result
            ? (this.getBestPerfFromResultTrials(result.newTrials, result.disciplineId, true) as {
                perf: string;
                wind: number | undefined;
                perfType: EPerfType;
              })
            : undefined;

        const rawBest = this.getBestPerfFromResultTrials(result.newTrials, result.disciplineId, false, true) as
          | number
          | undefined;

        const eventType = this.getLoadedDisciplineById(result.disciplineId)?.eventType;

        let lastSeasonBest$ = undefined;

        if (result.athletes?.[0]?.athlete && eventType) {
          lastSeasonBest$ = this.getLastSeasonBestIfBeaten(rawBest, result.athletes[0].athlete.id, eventType);
        }

        return {
          id: result.id,
          validation: result.validationStatus,
          rank: result?.competitionFeature ? '-' : result?.rank || '-',
          order: result?.startingOrder || '-',
          athlete,
          bib,
          category,
          club,
          liveId,
          bestPerf: bestPerf?.perf ?? '-',
          bestWind:
            eventType?.wind_mode !== 'N' ? (this.toNumber('' + bestPerf?.wind) === 0 ? '0.0' : bestPerf?.wind) : '-',
          certifiablePerf: certifiablePerf?.perf ?? '-',
          certifiableWind:
            eventType?.wind_mode !== 'N'
              ? this.toNumber('' + certifiablePerf?.wind) === 0
                ? '0.0'
                : certifiablePerf?.wind
              : '-',
          rawPerf: rawBest,
          //perfype: bestPerf?.perfType || EPerfType.UNKNOWN,
          points: result?.combinedPoints,
          lastSeasonBest$,
          fullResult: {
            ...result,
            discipline: { eventType: eventType as EventType } as IDiscipline,
          },
        } satisfies IResultGridData;
      })
      .sort((a, b) => {
        let sortKey: 'rank' | 'order' = 'rank';
        if (forAdminGrid && a.fullResult?.discipline?.eventType?.result_type === 'Distance') {
          sortKey = 'order';
        }
        if (sortByPerf) {
          const lowerIsBest = a.fullResult?.discipline?.eventType?.low2high === 1;
          return (
            (lowerIsBest ? +(a.rawPerf || 999999999999) : -(a.rawPerf || 0)) -
            (lowerIsBest ? +(b.rawPerf || 999999999999) : -(b.rawPerf || 0))
          );
        } else if (a[sortKey] !== '-' || b[sortKey] !== '-') {
          return (
            (typeof a[sortKey] === 'number' ? +a[sortKey] : 9999) -
            (typeof b[sortKey] === 'number' ? +b[sortKey] : 9999)
          );
        } else {
          const trialData = a.fullResult?.newTrials?.[0] || b.fullResult?.newTrials?.[0];

          let bestPerfA = this.toNumber((a.fullResult?.newTrials || []).find((t) => t.best === true)?.rankingPerf);
          let bestPerfB = this.toNumber((b.fullResult?.newTrials || []).find((t) => t.best === true)?.rankingPerf);

          if (bestPerfA && bestPerfB) {
            if (trialData?.perftype === EPerfType.TIME) {
              // lower is better
              bestPerfA = isNaN(+bestPerfA) ? 999999999999 : +bestPerfA;
              bestPerfB = isNaN(+bestPerfB) ? 999999999999 : +bestPerfB;
              return +bestPerfA - +bestPerfB;
            } else {
              // higher is better
              bestPerfA = isNaN(+bestPerfA) ? 0 : +bestPerfA;
              bestPerfB = isNaN(+bestPerfB) ? 0 : +bestPerfB;
              return +bestPerfB - +bestPerfA;
            }
          } else if (bestPerfA && !bestPerfB) {
            return -1;
          } else if (!bestPerfA && bestPerfB) {
            return 1;
          } else {
            return 0;
          }
        }
      });
  }

  toNumber(stringToConvert: string | null | undefined) {
    if (stringToConvert) {
      return +stringToConvert;
    }
    return undefined;
  }

  getBestPerfFromResultTrials(
    trials: INewTrials[] | null | undefined,
    disciplineId: string | null,
    getHomologationBest = false,
    getRawPerf = false,
  ) {
    const _trials = trials;
    let perf = undefined;
    let perfType = EPerfType.UNKNOWN;
    let wind = undefined;
    const eventType = this.getLoadedDisciplineById(disciplineId)?.eventType;
    if (_trials && _trials.length > 0) {
      for (const [i, trial] of _trials.entries()) {
        if (
          _trials &&
          trial?.[getHomologationBest ? 'homologationBest' : 'best'] /* ||
            i === _trials.length - 1 */
        ) {
          if (getRawPerf) {
            return trial?.rankingPerf ? +trial.rankingPerf : undefined;
          }
          perf =
            +(trial?.rankingPerf ?? -1) < 0
              ? '-'
              : perfFormatterFront(
                  this.toNumber(trial?.rankingPerf),
                  (trial?.perftype as EPerfType) || EPerfType.UNKNOWN,
                  eventType?.discipline_group as DisciplineGroups | undefined,
                );
          perfType = (trial?.perftype as EPerfType) || EPerfType.UNKNOWN;
          wind = trial?.windSpeed ? +trial.windSpeed : undefined;
          return { perf, wind, perfType };
        } else if (i === _trials.length - 1) {
          return { perf: '-', wind, perfType };
        }
      }
    }
    return { perf: '-', wind, perfType };
  }

  getCompetitionMissingDataFlags(disciplineOrigin?: PrismaDisciplineOrigin) {
    return combineLatest([this.selectedCompetitionData$, this.loadedResults$]).pipe(
      map(([competition, allResults]) => {
        const missingData: ICompetitionMissingData[] = [];

        const disciplines = disciplineOrigin
          ? competition.disciplines.filter((d) => d.origin === disciplineOrigin)
          : competition.disciplines;

        for (const discipline of disciplines) {
          // ? should venueType & disciplineGroup be defined by the eventType ?
          const disciplineData = {
            data: discipline,
            nbOfFlags: 0,
            hasCategories: true,
            hasEventType: true,
            hasDisciplineGroup: true,
            hasVenueType: true,
            hasResults: true,
            missingFromResults: {
              athlete: 0,
              category: 0,
            },
          };
          if (!discipline?.categories || discipline.categories?.length < 1) {
            disciplineData.nbOfFlags++;
            disciplineData.hasCategories = false;
          }
          if (discipline.disciplineGroup === DisciplineGroup.Unknown) {
            disciplineData.nbOfFlags++;
            disciplineData.hasDisciplineGroup = false;
          }
          if (!discipline.eventTypeId) {
            disciplineData.nbOfFlags++;
            disciplineData.hasEventType = false;
          }
          if (discipline.venueType === VenueType.Unknown) {
            disciplineData.nbOfFlags++;
            disciplineData.hasVenueType = false;
          }

          if (discipline.results) {
            for (const r of discipline.results) {
              const result = allResults[competition.eventNumber]?.[r.id];
              if (result) {
                if (!result?.athletes || result.athletes?.length < 1) {
                  disciplineData.nbOfFlags++;
                  disciplineData.missingFromResults.athlete++;
                }
                if (!result.categoryId) {
                  disciplineData.nbOfFlags++;
                  disciplineData.missingFromResults.category++;
                }
              } else {
                disciplineData.nbOfFlags++;
                disciplineData.hasResults = false;
              }
            }
          }
          if (disciplineData.nbOfFlags > 0) {
            missingData.push(disciplineData);
          }
        }
        return missingData;
      }),
    );
  }

  getCurrentEventDataAndRegistrations() {
    const eventNumber = this.selectedCompetition();
    return combineLatest([
      this.selectedCompetitionDisciplinesAndResults$,
      this.#apiService.getRegistrationsForEvent(eventNumber),
    ]).pipe(map(([data, registrations]) => ({ ...data, registrations })));
  }

  getRegistrationsForEvent() {
    return this.selectedCompetition$.pipe(
      first(),
      switchMap((eventNumber) => this.#apiService.getRegistrationsForEvent(eventNumber)),
      map((registrations) => this.arrangeRegistrationDataForAdmin(registrations)),
    );
  }

  arrangeRegistrationDataForAdmin(registrations: IEventRegistration[]) {
    const returnValue: {
      athletes: { [athleteId: string]: IRegistrationDataAthlete };
      disciplines: { [disciplineRef: string]: IRegistrationDataDiscipline };
      orders: IRegistrationDataOrder[];
    } = {
      athletes: {},
      disciplines: {},
      orders: [],
    };
    for (const data of registrations) {
      const order: IRegistrationDataOrder = {
        orderId: data.id,
        date: new Date(data.submittedAt || data.updatedAt),
        email: data.client.email,
        nbAthletes: 0,
        nbAthletesRefused: 0,
        nbDisciplines: 0,
        nbDisciplinesRefused: 0,
        nbUnaffiliated: 0,
        data,
      };
      // => sort items to get athletes first
      const orderItems = data.items.sort((a, b) => {
        if ('liveId' in a.metadata || 'affiliationNumber' in a.metadata) {
          return -1;
        } else if ('comment' in a.metadata) {
          return 1;
        }
        if ('liveId' in b.metadata || 'affiliationNumber' in b.metadata) {
          return 1;
        } else if ('comment' in b.metadata) {
          return -1;
        }
        return 0;
      });
      for (const item of orderItems) {
        if ('name' in item.metadata) {
          // => set athlete data
          if (!returnValue.athletes[item.metadata.athleteId]) {
            returnValue.athletes[item.metadata.athleteId] = {
              athleteId: item.metadata.athleteId,
              orderId: data.id,
              status: item.validationStatus,
              category: item.metadata.category || '',
              bib: item.metadata.bib || 0,
              club: item.metadata.club || '',
              liveId: item.metadata.liveId || '',
              date: new Date(item.createdAt),
              name: item.metadata.name,
              disciplines: [],
              data: item,
              isOneDayBib: 'affiliationNumber' in item.metadata || !('liveId' in item.metadata),
            };
          }
          order.nbAthletes++;
          if (item.validationStatus === ERegistrationItemStatus.REJECTED) {
            order.nbAthletesRefused++;
          }
          if (!('liveId' in item.metadata)) {
            order.nbUnaffiliated++;
          }
        } else if ('comment' in item.metadata) {
          // => set discipline data
          const athleteIds = [item.metadata.athleteId];
          if (item.metadata.relayTeam && item.metadata.relayAthletes) {
            if (returnValue.athletes[item.metadata.athleteId].relayTeam) {
              returnValue.athletes[item.metadata.athleteId].relayTeam![item.product.reference] =
                item.metadata.relayTeam.name;
              returnValue.athletes[item.metadata.athleteId].relayOrder![item.product.reference] =
                item.metadata.relayAthletes.find((a) => a.athleteId === item.metadata.athleteId)?.order || 0;
            } else {
              returnValue.athletes[item.metadata.athleteId].relayTeam = {
                [item.product.reference]: item.metadata.relayTeam.name,
              };
              returnValue.athletes[item.metadata.athleteId].relayOrder = {
                [item.product.reference]:
                  item.metadata.relayAthletes.find((a) => a.athleteId === item.metadata.athleteId)?.order || 0,
              };
            }

            for (const athlete of item.metadata.relayAthletes.filter((a) => a.athleteId !== item.metadata.athleteId)) {
              athleteIds.push(athlete.athleteId);
              const relayOrder = item.metadata.relayAthletes.find((a) => a.athleteId === athlete.athleteId)?.order;
              if (!returnValue.athletes[athlete.athleteId]) {
                returnValue.athletes[athlete.athleteId] = {
                  athleteId: athlete.athleteId,
                  orderId: data.id,
                  status: item.validationStatus,
                  category: athlete.category || '',
                  bib: athlete.bib || 0,
                  club: athlete.club || athlete.federation || athlete.localTeam || '',
                  liveId: athlete.liveId || '',
                  date: new Date(item.createdAt),
                  name: athlete.name,
                  disciplines: [],
                  data: item,
                  isOneDayBib: 'affiliationNumber' in athlete || !('liveId' in athlete),
                  relayTeam: {
                    [item.product.reference]: item.metadata.relayTeam.name,
                  },
                  relayOrder: { [item.product.reference]: relayOrder || 0 },
                };
                order.nbAthletes++;
              } else {
                if (returnValue.athletes[athlete.athleteId].relayTeam) {
                  returnValue.athletes[athlete.athleteId].relayTeam![item.product.reference] =
                    item.metadata.relayTeam.name;
                  returnValue.athletes[athlete.athleteId].relayOrder![item.product.reference] = relayOrder || 0;
                } else {
                  returnValue.athletes[athlete.athleteId].relayTeam = {
                    [item.product.reference]: item.metadata.relayTeam.name,
                  };
                  returnValue.athletes[athlete.athleteId].relayOrder = {
                    [item.product.reference]: relayOrder || 0,
                  };
                }
              }

              if (item.validationStatus === ERegistrationItemStatus.REJECTED) {
                order.nbAthletesRefused++;
              }
              if (!('liveId' in athlete)) {
                order.nbUnaffiliated++;
              }
            }
          }
          const eventNumber = this.selectedCompetition();
          const reference = item.product.reference;
          // const athleteId = item.metadata.athleteId;
          if (reference !== eventNumber) {
            if (!returnValue.disciplines[reference]) {
              const disciplineData = this.getLoadedDisciplineByRef(reference, PrismaDisciplineOrigin.REGISTRATION);
              returnValue.disciplines[reference] = {
                disciplineRef: reference,
                orderId: data.id,
                orderEmail: data.client.email,
                status: item.validationStatus,
                categories: disciplineData?.categories ? disciplineData?.categories.map((c: any) => c.abbr) : [],
                name: item.product.name,
                group: (disciplineData?.eventType?.discipline_group as DisciplineGroups) || null,
                perfType: getPerfTypeFromEventType(disciplineData?.eventType),
                athletes: [],
                data: { [athleteIds[0]]: item },
              };
            } else {
              returnValue.disciplines[reference].data[athleteIds[0]] = item;
            }
            order.nbDisciplines++;
            if (item.validationStatus === ERegistrationItemStatus.REJECTED) {
              order.nbDisciplinesRefused++;
            }
            // => add athlete(s) to discipline / discipline to athlete(s)

            for (const id of athleteIds) {
              const discipline = {
                ...returnValue.disciplines[reference],
                status: item.validationStatus,
                orderId: data.id,
                orderEmail: data.client.email,
              };
              discipline.data[id] = item;
              const athlete = {
                ...returnValue.athletes[id],
                orderId: data.id,
              };
              if (athlete?.disciplines) {
                athlete.disciplines.push(discipline);
              }
              if (discipline?.athletes) {
                discipline.athletes.push(athlete);
              }
            }
          }
        } else {
          console.error('UNKNOWN ITEM TYPE, SHOULD NOT HAPPEN !');
          console.debug('ITEM', item);
        }
      }
      returnValue.orders.push(order);
    }
    return {
      orders: returnValue.orders.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
      athletes: Object.values(returnValue.athletes).sort((a, b) => a.name.localeCompare(b.name)),
      disciplines: Object.values(returnValue.disciplines).sort((a, b) => a.name.localeCompare(b.name)),
    };
  }

  resetShop(eventNumber: string) {
    this.#store.dispatch(new ResetShop(eventNumber));
  }
  reSyncShop(eventNumber: string) {
    this.#store.dispatch(new ReSyncShop(eventNumber));
  }

  generateSyncCode(eventNumber: string) {
    this.#store.dispatch(new GenerateSyncCode(eventNumber));
  }

  getAllDisciplinesCategories() {
    const data = this.selectedCompetitionData();
    const categoryIds: string[] = [];
    const categories: NewCategory[] = [];

    for (const discipline of data?.disciplines || []) {
      for (const cat of discipline.categories || []) {
        if (!categoryIds.includes(cat.id)) {
          categoryIds.push(cat.id);
          categories.push(cat);
        }
      }
    }

    return categories
      .sort((a, b) => a.requiredGender.localeCompare(b.requiredGender))
      .sort((a, b) => (a.index === 'TC' ? 0 : a.requiredMinAge) - (b.index === 'TC' ? 0 : b.requiredMinAge));
  }

  getRegistrationOneDayBibPrice() {
    return this.selectedCompetitionData()?.registrationDetail?.oneDayBibPrice || 0;
  }

  getSelectedCompetitionSeason = computed(() => {
    const startDate = this.selectedCompetitionData()?.startDate;
    if (startDate) {
      return getAthleticSeasonFromDate2(startDate);
    }
    return '';
  });
}

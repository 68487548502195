import { CdkScrollable } from '@angular/cdk/scrolling';
import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { BeathleticsUiModule } from '@beathletics/beathletics-ui';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'beathletics-competition-registrations-filter-export-dialog',
  templateUrl: './competition-registrations-filter-export-dialog.component.html',
  imports: [
    TranslocoDirective,
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    MatRadioGroup,
    ReactiveFormsModule,
    FormsModule,
    MatRadioButton,
    BeathleticsUiModule,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class CompetitionRegistrationsFilterExportDialogComponent {
  #dialogRef = inject(MatDialogRef<CompetitionRegistrationsFilterExportDialogComponent>);
  filter?: 'none' | 'rejected' | 'pending' | 'full';

  validateSelection() {
    this.#dialogRef.close(this.filter);
  }
}

import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { PrismaGender } from '@beathletics/api-interfaces';
import type { NewCategory } from '@prisma/client';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { of } from 'rxjs';

@Component({
  selector: 'app-category-selection-dialog',
  template: `
    <section>
      <header class="text-center">
        <h3 class="font-medium text-lg uppercase">Sélection de catégorie(s)</h3>
      </header>
      <hr class="my-4" />
      @if (data.allowedCategories.length > 0) {
        <mat-form-field class="w-full">
          <mat-label>Categories</mat-label>
          <mat-select [(value)]="selectedCategories" multiple>
            <mat-option>
              <ngx-mat-select-search
                placeholderLabel="Rechercher..."
                noEntriesFoundLabel="Aucun résultat"
                [formControl]="categoryFilterCtrl"
              ></ngx-mat-select-search>
            </mat-option>
            @for (category of filteredCategories(); track category.id) {
              <mat-option [value]="category">{{ category.abbr }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      } @else {
        <div class="h-16 flex justify-center items-center">
          <em class="text-gray-500">Aucune catégorie disponible</em>
        </div>
      }
      <mat-dialog-actions class="!justify-between">
        <button mat-button color="warn" [mat-dialog-close]="null">Annuler</button>
        <button
          mat-button
          color="primary"
          [mat-dialog-close]="selectedCategories"
          [disabled]="selectedCategories.length === 0"
        >
          Confirmer
        </button>
      </mat-dialog-actions>
    </section>
  `,
  imports: [
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
  ],
})
export class CategorySelectionDialogComponent {
  data = inject(MAT_DIALOG_DATA) as {
    allowedCategories: { id: string; abbr: string; sortOrder: number; requiredGender: PrismaGender }[] | NewCategory[];
  };
  selectedCategories: { id: string; abbr: string; sortOrder: number; requiredGender: PrismaGender }[] | NewCategory[] =
    [];

  categoryFilterCtrl = new FormControl<string>('');
  categoryFilter = toSignal<string | null>(this.categoryFilterCtrl.valueChanges || of(''));
  filteredCategories = computed(() => {
    const filterValue = this.categoryFilter()?.toLowerCase() ?? '';
    return this.data.allowedCategories.filter((cat) => cat.abbr.toLowerCase().includes(filterValue));
  });
}

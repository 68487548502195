import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EventTypeWithCategories, IClub, IVenue } from '@beathletics/api-interfaces';
import type { NewCategory, NewCountry, NewFederation } from '@prisma/client';

@Injectable({
  providedIn: 'root',
})
export class SharedApiService {
  #http = inject(HttpClient);

  // *- CATEGORIES -*

  getAllCategories = () => this.#http.get<NewCategory[]>('/api/shared/categories');

  // *- CLUBS -*

  getAllClubs = () => this.#http.get<IClub[]>('/api/shared/clubs');

  processClubProvinceFile = (file: File) => {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.#http.post<IClub[]>(`/api/shared/clubs/province/file`, formData);
  };

  // *- COUNTRIES -*

  getAllCountries = () => this.#http.get<NewCountry[]>('/api/shared/countries');

  // *- EVENT TYPES -*

  getAllEventTypes = () => this.#http.get<EventTypeWithCategories[]>(`/api/shared/event-types`);

  editEventTypeProperty = (body: { eventTypeId: string; property: string; value: string | number }) =>
    this.#http.put<EventTypeWithCategories>(`/api/shared/edit/event-type`, body);

  // *- FEDERATIONS -*

  getAllFederations = () => this.#http.get<NewFederation[]>(`/api/shared/federations`);

  // *- VENUES -*

  getAllVenues = () => this.#http.get<IVenue[]>(`/api/shared/venues`);

  editVenueProperty = (body: { venueId: string; property: string; value: string }) =>
    this.#http.put<IVenue>(`/api/shared/edit/venue`, body);

  processVenueFile = (file: File) => {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.#http.post<IVenue[]>(`/api/shared/venues/file`, formData);
  };

  createNewVenue = (data: Partial<IVenue>) => this.#http.post<IVenue>(`/api/shared/venue`, data);

  deleteVenues = (venues: IVenue[]) => this.#http.delete<string[]>(`/api/shared/venues/delete`, { body: venues });

  switchCompetitionsVenues = (data: { venueIdOne: string; venueIdTwo: string }) =>
    this.#http.put<{ venueOne: IVenue; venueTwo: IVenue }>(`/api/shared/venues/switch`, data);
}

<section *transloco="let t">
  <h2 mat-dialog-title>Export des inscriptions</h2>
  <mat-dialog-content class="!overflow-visible">
    <label>Quels inscriptions souhaitez-vous dans l'export ?</label>
    <mat-radio-group color="primary" class="flex flex-col" [(ngModel)]="filter">
      <mat-radio-button class="mt-2" value="none">Toutes les inscriptions</mat-radio-button>
      <mat-radio-button class="mt-2" value="rejected"
        >Toutes les inscriptions sauf celles qui ont été refusées</mat-radio-button
      >
      <mat-radio-button class="mt-2" value="pending"
        >Les inscriptions validées et celles qui ont été correctement soumises</mat-radio-button
      >
      <mat-radio-button class="mt-2" value="full">Uniquement les inscriptions qui ont été validées</mat-radio-button>
    </mat-radio-group>
  </mat-dialog-content>
  <mat-dialog-actions class="flex !justify-between !mx-4">
    <button mat-raised-button color="primary" [disabled]="!filter" (click)="validateSelection()">
      {{ t('BASIC_VALIDATE') }}
    </button>
    <button mat-raised-button color="warn" mat-dialog-close cdkFocusInitial>
      {{ t('BASIC_CANCEL') }}
    </button>
  </mat-dialog-actions>
</section>

import { IVenue } from '@beathletics/api-interfaces';

const apiTag = '[Shared Data API] ';

export class LoadSharedData {
  static readonly type = apiTag + 'Load all shared state data';
}

// *- CATEGORIES -*

export class LoadCategories {
  static readonly type = apiTag + 'Load all categories';
}

// *- CLUBS -*

export class LoadClubs {
  static readonly type = apiTag + 'Load all clubs';
}

export class ProcessClubProvinceFile {
  static readonly type = apiTag + 'Process club province file';
  constructor(public file: File) {}
}

// *- COUNTRIES -*

export class LoadCountries {
  static readonly type = apiTag + 'Load all countries';
}

// *- EVENT TYPES -*

export class LoadEventTypes {
  static readonly type = apiTag + 'Load all event types';
}

export class EditEventTypeProperty {
  static readonly type = apiTag + 'Edit event type property';
  constructor(
    public eventTypeId: string,
    public property: string,
    public value: string | number,
  ) {}
}

// *- FEDERATIONS -*

export class LoadFederations {
  static readonly type = apiTag + 'Load all federations';
}

// *- VENUES -*

export class LoadVenues {
  static readonly type = apiTag + 'Load all venues';
}

export class EditVenueProperty {
  static readonly type = apiTag + 'Edit venue property';
  constructor(
    public venueId: string,
    public property: string,
    public value: string,
  ) {}
}

export class ProcessVenueFile {
  static readonly type = apiTag + 'Process venue file';
  constructor(public file: File) {}
}

export class CreateNewVenue {
  static readonly type = apiTag + 'Create new venue';
  constructor(public data: Partial<IVenue>) {}
}

export class DeleteVenues {
  static readonly type = apiTag + 'Delete venues';
  constructor(public venues: IVenue[]) {}
}

export class SwitchCompetitionsVenues {
  static readonly type = apiTag + 'Switch competitions venues';
  constructor(
    public venueIdOne: string,
    public venueIdTwo: string,
  ) {}
}

import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { PrismaDisciplineOrigin } from '@beathletics/api-interfaces';
import { NewCompetitionService } from '@beathletics/beathletics-data-state';
import { TranslocoDirective } from '@jsverse/transloco';
import { EmailManagementService } from '../../../../../shared/email-management.service';
import { CompetitionMissingDataComponent } from '../competition-missing-data/competition-missing-data.component';

@Component({
  selector: 'beathletics-competition-imports-info-dialog',
  templateUrl: './competition-imports-info-dialog.component.html',
  imports: [TranslocoDirective, CompetitionMissingDataComponent, MatButton, MatDialogClose, AsyncPipe],
})
export class CompetitionImportsInfoDialogComponent {
  #dialogRef = inject<MatDialogRef<CompetitionImportsInfoDialogComponent>>(MatDialogRef);
  #competitionService = inject(NewCompetitionService);
  #emailService = inject(EmailManagementService);
  data = inject(MAT_DIALOG_DATA);

  missingData$ = this.#competitionService.getCompetitionMissingDataFlags(
    this.data.onlySchedule ? PrismaDisciplineOrigin.REGISTRATION : PrismaDisciplineOrigin.RESULT_FILE,
  );

  reloadSchedule() {
    this.#dialogRef.close(false);
  }

  activateRegistration() {
    this.#dialogRef.close(true);
  }

  sendEmailToJa() {
    if (this.data.ja) {
      this.#emailService.sendCompetitionValidationEmail(this.data.ja);
    }
  }
}

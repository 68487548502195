import { DatePipe, KeyValuePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogClose } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { IAdminRegistrationOrder } from '@beathletics/api-interfaces';
import { TypedDialog } from '@beathletics/beathletics-ui';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-order-details-dialog',
  template: `
    <section *transloco="let t" class="w-[480px]">
      <div class="text-center pb-5">
        <h3 class="text-xl font-medium whitespace-nowrap overflow-hidden text-ellipsis">
          {{ data.order.submittedAt | date: 'dd/MM/y, HH:mm' }} - {{ data.title }}
        </h3>
      </div>
      <div class="max-h-[60vh] overflow-y-auto bg-slate-100 px-4 py-2">
        @for (athlete of data.order.athletes | keyvalue; track athlete.key) {
          <div class="mt-2">
            <span class="font-medium whitespace-nowrap overflow-hidden text-ellipsis"
              >&bull; &nbsp;{{ athlete.value.name }} ({{ athlete.value.bib }}) {{ athlete.value.category }} -
              {{ athlete.value.club }}</span
            >
            <mat-list>
              @for (discipline of athlete.value.disciplines | keyvalue; track discipline.key) {
                <mat-list-item lines="2">
                  <span matListItemTitle
                    >{{ discipline.value.name }} ({{ discipline.value.personalRecordFormated || '-' }})</span
                  >
                  <span>{{ discipline.value.comment || '-' }}</span>
                </mat-list-item>
              }
            </mat-list>
          </div>
        }
      </div>
      <div class="flex justify-center pt-5">
        <button mat-raised-button [mat-dialog-close]="null">{{ t('BASIC_CLOSE') }}</button>
      </div>
    </section>
  `,
  imports: [DatePipe, KeyValuePipe, MatButtonModule, MatDialogClose, MatListModule, TranslocoDirective],
})
export class OrderDetailsDialogComponent extends TypedDialog<{ title: string; order: IAdminRegistrationOrder }, void> {}

import { isPlatformBrowser } from '@angular/common';
import { computed, inject, Injectable, NgZone, PLATFORM_ID, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { IClub, IPrimeTreeParent, IVenue } from '@beathletics/api-interfaces';
import { Store } from '@ngxs/store';
import type { NewFederation } from '@prisma/client';
import { map } from 'rxjs';
import {
  CreateNewVenue,
  DeleteVenues,
  EditEventTypeProperty,
  EditVenueProperty,
  ProcessClubProvinceFile,
  ProcessVenueFile,
  SwitchCompetitionsVenues,
} from './shared-data.action';
import { SharedDataState } from './shared-data.state';

@Injectable()
export class SharedDataService {
  #store = inject(Store);
  #minutesSignalSource = signal(Date.now());
  minutesSignal = this.#minutesSignalSource.asReadonly();
  #platformId = inject(PLATFORM_ID);

  categories$ = this.#store.select(SharedDataState.categories);
  clubs$ = this.#store.select(SharedDataState.clubs);
  countries$ = this.#store.select(SharedDataState.countries);
  federations$ = this.#store.select(SharedDataState.federations);
  foreignFederations$ = this.#store.select(SharedDataState.foreignFederations);
  eventTypes$ = this.#store.select(SharedDataState.eventTypes);
  venues$ = this.#store.select(SharedDataState.venues);

  categories = toSignal(this.categories$);
  clubs = toSignal(this.clubs$, { requireSync: true });
  countries = toSignal(this.countries$);
  countriesOrdered = computed(() => {
    const fraNeLuxGer =
      this.countries()?.filter(
        (c) =>
          c.waCode === 'BEL' || c.waCode === 'FRA' || c.waCode === 'NED' || c.waCode === 'LUX' || c.waCode === 'GER',
      ) || [];
    const other =
      this.countries()?.filter(
        (c) =>
          c.waCode !== 'BEL' && c.waCode !== 'FRA' && c.waCode !== 'NED' && c.waCode !== 'LUX' && c.waCode !== 'GER',
      ) || [];
    return [...fraNeLuxGer, ...other];
  });
  federations = toSignal(this.federations$);
  foreignFederations = toSignal(this.foreignFederations$);
  foreignFederationsOrdered = computed(() => {
    const fraNeLuxGer =
      this.foreignFederations()?.filter(
        (f) => f.abbr === 'FRA' || f.abbr === 'NED' || f.abbr === 'LUX' || f.abbr === 'GER',
      ) || [];
    const other =
      this.foreignFederations()?.filter(
        (f) => f.abbr !== 'FRA' && f.abbr !== 'NED' && f.abbr !== 'LUX' && f.abbr !== 'GER',
      ) || [];
    return [...fraNeLuxGer, ...other];
  });
  eventTypes = toSignal(this.eventTypes$);
  venues = toSignal(this.venues$);

  constructor() {
    const zone = inject(NgZone);
    if (isPlatformBrowser(this.#platformId)) {
      zone.runOutsideAngular(() => {
        setInterval(() => {
          zone.run(() => {
            this.#minutesSignalSource.set(Date.now());
          });
        }, 1000 * 60);
      });
    }
  }

  // *- CATEGORIES -*

  getCategoryById(id: string) {
    return this.categories()?.find((c) => c.id === id);
  }

  getCategoryByAbbrev(abbr: string) {
    return this.categories()?.find((c) => c.abbr === abbr);
  }

  getCategorySortOrder(cat: string) {
    const category = this.categories()?.find((c) => c.abbr === cat || c.name === cat);
    return category?.sortOrder || 0;
  }

  // *- CLUBS -*

  getClubsByFedTree(allowFedSelection = true) {
    return this.clubs$.pipe(
      map((clubs) => {
        const feds = {} as Record<string, IPrimeTreeParent<IClub, NewFederation>>;
        for (const club of clubs) {
          if (club.federation) {
            if (!feds[club.federation.id]) {
              feds[club.federation.id] = {
                label: club.federation.abbr,
                data: club.federation,
                children: [],
                key: club.federation.abbr,
                selectable: allowFedSelection,
              };
            }
            feds[club.federation.id].children.push({
              label: club.abbr + ' ' + club.name,
              data: club,
              key: club.abbr,
              type: 'node',
            });
          }
        }
        return Object.values(feds).sort((a, b) => a.label.localeCompare(b.label));
      }),
    );
  }

  getClubAbbrevById(id: string) {
    return this.clubs()?.find((c) => c.id === id)?.abbr || '';
  }

  getClubByAbbrev(abbrev: string) {
    return this.clubs()?.find((c) => c.abbr === abbrev);
  }

  getClubLeadersCategories() {
    return this.categories()
      ?.filter((c) => c.index !== 'KA' && c.index !== 'BE' && c.index !== 'PU' && c.index !== 'MI' && c.index !== 'TC')
      .sort((a, b) => a.requiredGender.localeCompare(b.requiredGender))
      .sort((a, b) => (a.requiredMaxAge || a.requiredMinAge) - (b.requiredMaxAge || b.requiredMinAge));
  }

  processClubProvinceFile(file: File) {
    return this.#store.dispatch(new ProcessClubProvinceFile(file));
  }

  // *- COUNTRIES -*

  getAMCountryCodeByAbbrev(abbrevWa?: string, unaffiliated = false) {
    if (abbrevWa === 'BEL' && unaffiliated) {
      return 999;
    } else if (abbrevWa) {
      return this.countries()?.find((c) => c.waCode === abbrevWa)?.amId || 999;
    }
    return 999;
  }

  getAMCountryIso3ByAbbrev(abbrevWa?: string) {
    if (abbrevWa) {
      return this.countries()?.find((c) => c.waCode === abbrevWa)?.iso3 || '';
    }
    return '';
  }

  // *- EVENT TYPES -*

  editEventTypeProperty(venueId: string, property: string, value: string) {
    return this.#store.dispatch(new EditEventTypeProperty(venueId, property, value));
  }

  // *- FEDERATIONS -*

  getFederationByAbbrev(abbrev: string) {
    return this.federations()?.find((f) => f.abbr === abbrev);
  }

  // *- VENUES -*

  editVenueProperty(venueId: string, property: string, value: string) {
    return this.#store.dispatch(new EditVenueProperty(venueId, property, value));
  }

  processVenueFile(file: File) {
    return this.#store.dispatch(new ProcessVenueFile(file));
  }

  createNewVenue(data: Partial<IVenue>) {
    return this.#store.dispatch(new CreateNewVenue(data));
  }

  deleteVenues(venues: IVenue[]) {
    return this.#store.dispatch(new DeleteVenues(venues));
  }

  switchCompetitionsVenues(venueIdOne: string, venueIdTwo: string) {
    return this.#store.dispatch(new SwitchCompetitionsVenues(venueIdOne, venueIdTwo));
  }
}

import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatRadioChange, MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { IBibData } from '@beathletics/api-interfaces';
import { TranslocoDirective } from '@jsverse/transloco';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BeathleticsUiModule } from '@beathletics/beathletics-ui';
import { MatButton } from '@angular/material/button';

enum EBibChoiceType {
  ALL = 'all',
  AFFILIATED = 'affiliated',
  UNAFFILIATED = 'unaffiliated',
  CUSTOM = 'custom',
}

@Component({
  selector: 'beathletics-competition-registrations-bib-dialog',
  template: `
    <section *transloco="let t">
      <h2 mat-dialog-title>{{ t('EXPORT_BIB_PDF') }}</h2>
      <mat-dialog-content class="!overflow-visible">
        <label>{{ t('REGISTRATION_BIBS_WHICH_TO_GENERATE') }}</label>
        <mat-radio-group color="primary" class="flex flex-col" [(ngModel)]="choice" (change)="setChoice($event)">
          @for (possibility of possibilities; track possibility) {
            <mat-radio-button class="mt-2" [value]="possibility">{{
              t('REGISTRATION_BIBS_' + possibility, { n: data.bibs.length })
            }}</mat-radio-button>
          }
        </mat-radio-group>
        @if (choice === 'custom') {
          <beathletics-prime-list-selector
            class="my-1"
            selectionMode="checkbox"
            [label]="t('REGISTRATION_BIBS_TO_GENERATE')"
            [dataList]="bibList"
            [customOverview]="true"
            (selectionChange)="changeSelection($any($event))"
          ></beathletics-prime-list-selector>
        }
      </mat-dialog-content>
      <mat-dialog-actions class="flex !justify-between !mx-4">
        <button mat-raised-button color="primary" [disabled]="selectedBibs.length === 0" (click)="validateSelection()">
          {{ t('BASIC_VALIDATE') }}
        </button>
        <button mat-raised-button color="warn" mat-dialog-close cdkFocusInitial>
          {{ t('BASIC_CANCEL') }}
        </button>
      </mat-dialog-actions>
    </section>
  `,
  imports: [
    TranslocoDirective,
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    MatRadioGroup,
    ReactiveFormsModule,
    FormsModule,
    MatRadioButton,
    BeathleticsUiModule,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class CompetitionRegistrationsBibDialogComponent {
  possibilities = Object.values(EBibChoiceType);
  choice?: EBibChoiceType;
  bibList = this.data.bibs.map((b) => ({ label: '' + b.bib, value: b }));
  selectedBibs: IBibData[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bibs: IBibData[] },
    private dialogRef: MatDialogRef<CompetitionRegistrationsBibDialogComponent>,
  ) {}

  setChoice(change: MatRadioChange & { value: EBibChoiceType }) {
    if (change.value === EBibChoiceType.ALL) {
      this.selectedBibs = this.bibList.map((b) => b.value);
    } else if (change.value === EBibChoiceType.AFFILIATED) {
      this.selectedBibs = this.data.bibs.filter((b) => b.bib < 9900);
    } else if (change.value === EBibChoiceType.UNAFFILIATED) {
      this.selectedBibs = this.data.bibs.filter((b) => b.bib >= 9900);
    } else {
      return;
    }
  }

  changeSelection(selection: string[]) {
    this.selectedBibs = this.data.bibs.filter((b) => selection.includes('' + b.bib));
  }

  validateSelection() {
    this.dialogRef.close(this.selectedBibs);
  }
}

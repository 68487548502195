<section class="w-[520px]">
  <header class="text-center">
    <h3 class="font-medium text-xl">{{ data.eventNumber }} - Ajouter un contact</h3>
  </header>
  <hr class="my-4" />
  <div>
    <mat-button-toggle-group [(ngModel)]="contactFunction" (change)="filterContactsByFunction($event)" class="w-full">
      <mat-button-toggle value="secretary" class="w-1/3">Secrétaire</mat-button-toggle>
      <mat-button-toggle value="ja" class="w-1/3">Juge-arbitre</mat-button-toggle>
      <mat-button-toggle value="homologation" class="w-1/3">Homologation</mat-button-toggle>
    </mat-button-toggle-group>

    @if (contactFunction) {
      <div class="flex flex-col justify-center items-center">
        @if (filteredUsers$ | async; as contacts) {
          <h4 class="font-medium my-6">Choisir le contact</h4>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Contact</mat-label>
            <mat-select (selectionChange)="selectContact($event)">
              @for (contact of contacts; track contact) {
                <mat-option [value]="contact"
                  >{{ contact.lastName }}, {{ contact.firstName }} ({{ contact.email }})
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        } @else {
          <mat-spinner class="mt-6" [diameter]="60"></mat-spinner>
        }
      </div>
    }
  </div>
  <div class="mt-4 flex justify-between">
    <button mat-raised-button mat-dialog-close color="warn" class="w-24">Annuler</button>
    <button
      mat-raised-button
      mat-dialog-close
      color="primary"
      class="w-24"
      [disabled]="!contactToAdd"
      (click)="assignContact()"
    >
      Valider
    </button>
  </div>
</section>

import { Injectable, inject } from '@angular/core';
import { EventTypeWithCategories, IClub, IVenue } from '@beathletics/api-interfaces';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import type { NewCategory, NewCountry, NewFederation } from '@prisma/client';
import { produce } from 'immer';
import { tap } from 'rxjs';
import { SharedApiService } from './shared-api.service';
import {
  CreateNewVenue,
  DeleteVenues,
  EditEventTypeProperty,
  EditVenueProperty,
  LoadCategories,
  LoadClubs,
  LoadCountries,
  LoadEventTypes,
  LoadFederations,
  LoadSharedData,
  LoadVenues,
  ProcessClubProvinceFile,
  ProcessVenueFile,
  SwitchCompetitionsVenues,
} from './shared-data.action';

export interface SharedDataStateModel {
  categories: NewCategory[];
  clubs: { [clubId: string]: IClub };
  countries: NewCountry[];
  eventTypes: { [eventTypeId: string]: EventTypeWithCategories };
  federations: NewFederation[];
  venues: { [venueId: string]: IVenue };
}

@State<SharedDataStateModel>({
  name: 'shared',
  defaults: {
    categories: [],
    clubs: {},
    countries: [],
    eventTypes: {},
    federations: [],
    venues: {},
  },
})
@Injectable()
export class SharedDataState implements NgxsOnInit {
  private apiService = inject(SharedApiService);
  private store = inject(Store);

  @Selector()
  static categories(state: SharedDataStateModel) {
    return state.categories;
  }

  @Selector()
  static clubs(state: SharedDataStateModel) {
    return Object.values(state.clubs).sort((a, b) => a.abbr.localeCompare(b.abbr));
  }

  @Selector()
  static countries(state: SharedDataStateModel) {
    return state.countries;
  }

  @Selector()
  static eventTypes(state: SharedDataStateModel) {
    return Object.values(state.eventTypes).sort((a, b) => +a.type_id - +b.type_id);
  }

  @Selector()
  static federations(state: SharedDataStateModel) {
    return state.federations;
  }

  @Selector()
  static foreignFederations(state: SharedDataStateModel) {
    return state.federations
      .filter((f) => f.abbr !== 'LBFA' && f.abbr !== 'VAL' && f.abbr !== 'LRBA-KBAB')
      .sort((a, b) => a.country.localeCompare(b.country));
  }

  @Selector()
  static venues(state: SharedDataStateModel) {
    return Object.values(state.venues).sort((a, b) => a.name.localeCompare(b.name));
  }

  ngxsOnInit(ctx: StateContext<SharedDataStateModel>) {
    ctx.dispatch(new LoadSharedData());
  }

  @Action(LoadSharedData)
  loadSharedData() {
    this.store.dispatch([
      new LoadCategories(),
      new LoadClubs(),
      new LoadCountries(),
      new LoadFederations(),
      new LoadEventTypes(),
      new LoadVenues(),
    ]);
  }

  // *- CATEGORIES -*

  @Action(LoadCategories)
  loadCategories(ctx: StateContext<SharedDataStateModel>) {
    const state = ctx.getState();
    if (!state.categories || state.categories.length < 1) {
      return this.apiService.getAllCategories().pipe(
        tap((categories) => {
          return ctx.setState(
            produce((draft: SharedDataStateModel) => {
              draft.categories = categories;
            }),
          );
        }),
      );
    } else {
      return state.categories;
    }
  }

  // *- CLUBS -*

  @Action(LoadClubs)
  loadClubs(ctx: StateContext<SharedDataStateModel>) {
    const state = ctx.getState();
    if (!state.clubs || Object.keys(state.clubs).length < 1) {
      return this.apiService.getAllClubs().pipe(
        tap((clubs) => {
          return ctx.setState(
            produce((draft: SharedDataStateModel) => {
              for (const club of clubs) {
                draft.clubs[club.id] = club;
              }
            }),
          );
        }),
      );
    } else {
      return state.clubs;
    }
  }

  @Action(ProcessClubProvinceFile)
  processClubProvinceFile(ctx: StateContext<SharedDataStateModel>, { file }: ProcessClubProvinceFile) {
    return this.apiService.processClubProvinceFile(file).pipe(
      tap((clubs) => {
        return ctx.setState(
          produce((draft: SharedDataStateModel) => {
            for (const club of clubs) {
              draft.clubs[club.id] = club;
            }
          }),
        );
      }),
    );
  }

  // *- COUNTRIES -*

  @Action(LoadCountries)
  loadCountries(ctx: StateContext<SharedDataStateModel>) {
    const state = ctx.getState();
    if (!state.countries || state.countries.length < 1) {
      return this.apiService.getAllCountries().pipe(
        tap((countries) => {
          return ctx.setState(
            produce((draft: SharedDataStateModel) => {
              draft.countries = countries.sort((a, b) => (a.name > b.name ? 1 : -1));
            }),
          );
        }),
      );
    } else {
      return state.countries;
    }
  }

  // *- EVENT TYPES -*

  @Action(LoadEventTypes)
  loadEventTypes(ctx: StateContext<SharedDataStateModel>) {
    const state = ctx.getState();
    if (!state.eventTypes || Object.keys(state.eventTypes).length < 1) {
      return this.apiService.getAllEventTypes().pipe(
        tap((eventTypes) => {
          return ctx.setState(
            produce((draft: SharedDataStateModel) => {
              for (const eventType of eventTypes) {
                draft.eventTypes[eventType.id] = eventType;
              }
            }),
          );
        }),
      );
    } else {
      return state.eventTypes;
    }
  }

  @Action(EditEventTypeProperty)
  editEventTypeProperty(ctx: StateContext<SharedDataStateModel>, action: EditEventTypeProperty) {
    const state = ctx.getState();
    ctx.patchState({
      eventTypes: {
        ...state.eventTypes,
        [action.eventTypeId]: { ...state.eventTypes[action.eventTypeId], isUpdating: true },
      },
    });
    return this.apiService.editEventTypeProperty(action).pipe(
      tap((eventType) => {
        return ctx.setState(
          produce((draft: SharedDataStateModel) => {
            draft.eventTypes[eventType.id] = { ...eventType, isUpdating: false };
          }),
        );
      }),
    );
  }

  // *- FEDERATIONS -*

  @Action(LoadFederations)
  loadFederations(ctx: StateContext<SharedDataStateModel>) {
    const state = ctx.getState();
    if (!state.federations || state.federations.length < 1) {
      return this.apiService.getAllFederations().pipe(
        tap((federations) => {
          return ctx.setState(
            produce((draft: SharedDataStateModel) => {
              draft.federations = federations;
            }),
          );
        }),
      );
    } else {
      return state.federations;
    }
  }

  // *- VENUES -*

  @Action(LoadVenues)
  loadVenues(ctx: StateContext<SharedDataStateModel>) {
    const state = ctx.getState();
    if (!state.venues || Object.keys(state.venues).length === 0) {
      return this.apiService.getAllVenues().pipe(
        tap((venues) => {
          return ctx.setState(
            produce((draft: SharedDataStateModel) => {
              for (const venue of venues) {
                draft.venues[venue.id] = { ...venue, isUpdating: false };
              }
            }),
          );
        }),
      );
    } else {
      return state.venues;
    }
  }

  @Action(EditVenueProperty)
  editVenueProperty(ctx: StateContext<SharedDataStateModel>, action: EditVenueProperty) {
    const state = ctx.getState();
    ctx.patchState({
      venues: {
        ...state.venues,
        [action.venueId]: { ...state.venues[action.venueId], isUpdating: true },
      },
    });
    return this.apiService.editVenueProperty(action).pipe(
      tap((venue) => {
        return ctx.setState(
          produce((draft: SharedDataStateModel) => {
            draft.venues[venue.id] = { ...venue, isUpdating: false };
          }),
        );
      }),
    );
  }

  @Action(ProcessVenueFile)
  processVenueFile(ctx: StateContext<SharedDataStateModel>, { file }: ProcessVenueFile) {
    return this.apiService.processVenueFile(file).pipe(
      tap((venues) => {
        return ctx.setState(
          produce((draft: SharedDataStateModel) => {
            for (const venue of venues) {
              draft.venues[venue.id] = { ...venue, isUpdating: false };
            }
          }),
        );
      }),
    );
  }

  @Action(CreateNewVenue)
  createNewVenue(ctx: StateContext<SharedDataStateModel>, action: CreateNewVenue) {
    return this.apiService.createNewVenue(action.data).pipe(
      tap((venue) => {
        return ctx.setState(
          produce((draft: SharedDataStateModel) => {
            draft.venues[venue.id] = { ...venue, isUpdating: false };
          }),
        );
      }),
    );
  }

  @Action(DeleteVenues)
  deleteVenues(ctx: StateContext<SharedDataStateModel>, action: DeleteVenues) {
    return this.apiService.deleteVenues(action.venues).pipe(
      tap((venueIds) => {
        return ctx.setState(
          produce((draft: SharedDataStateModel) => {
            for (const id of venueIds) {
              delete draft.venues[id];
            }
          }),
        );
      }),
    );
  }

  @Action(SwitchCompetitionsVenues)
  switchCompetitionsVenues(ctx: StateContext<SharedDataStateModel>, action: SwitchCompetitionsVenues) {
    return this.apiService.switchCompetitionsVenues(action).pipe(
      tap((data) => {
        return ctx.setState(
          produce((draft: SharedDataStateModel) => {
            for (const venue in data) {
              const key = venue as keyof typeof data;
              draft.venues[data[key].id] = { ...data[key], isUpdating: false };
            }
          }),
        );
      }),
    );
  }
}

<ng-container *transloco="let t">
  @for (discipline of data; track discipline) {
    <div>
      <h4 class="font-bold text-base">
        {{ discipline.data.name }}
        @if (!origin) {
          ({{ discipline.data.origin }})
        }
      </h4>
      <ul class="mb-[10px]">
        @if (!discipline.hasCategories) {
          <li>&bull; {{ t('ERROR_DISCIPLINE_WITHOUT_CATEGORY') }}</li>
        }
        @if (!discipline.hasEventType) {
          <li>&bull; {{ t('ERROR_DISCIPLINE_WITHOUT_EVENT_TYPE') }}</li>
        }
        @if (!discipline.hasDisciplineGroup && !onlySchedule) {
          <li>&bull; {{ t('ERROR_DISCIPLINE_WITHOUT_GROUP') }}</li>
        }
        @if (!discipline.hasVenueType && !onlySchedule) {
          <li>&bull; {{ t('ERROR_DISCIPLINE_WITHOUT_VENUE') }}</li>
        }
        @if (discipline.missingFromResults.athlete > 0) {
          <li>
            &bull; {{ discipline.missingFromResults.athlete }}
            @if (discipline.missingFromResults.athlete === 1) {
              {{ t('ERROR_RESULT_WITHOUT_ATHLETE') }}
            } @else {
              {{ t('ERROR_RESULTS_WITHOUT_ATHLETES') }}
            }
          </li>
        }
        @if (discipline.missingFromResults.category > 0) {
          <li>
            &bull; {{ discipline.missingFromResults.category }}
            @if (discipline.missingFromResults.category === 1) {
              {{ t('ERROR_RESULT_WITHOUT_CATEGORY') }}
            } @else {
              {{ t('ERROR_RESULTS_WITHOUT_CATEGORIES') }}
            }
          </li>
        }
      </ul>
    </div>
  }
</ng-container>
